import React from 'react'

import * as S from './styled'

const PostFooter = () => {
  return (
    <S.PostFooter>      
      <S.PostFooterContent>
        <S.Title>
          Did you find a problem with the text? 
          Help me fix it! ! <span
          className="small"
          role="img"
          aria-label="Emoji sorrindo">😊</span>
        </S.Title>

        {/* <p>Esse projeto é open source, então basta alterar o arquivo de texto, <a href="https://github.com/felipefialho/okkhor.org/tree/master/content/posts" target="_blank" rel="noopener noreferrer" title="Alterar texto">diretamente no Github</a> e abrir um pull-request. É uma maneira simples e eficaz de contribuir com a comunidade de desenvolvimento web.</p> */}
      </S.PostFooterContent>

      <S.PostFooterContent>
        <S.Title>Follow me around! <span
          className="small"
          role="img"
          aria-label="Emoji mostrando a lingua">🤔</span>
        </S.Title>

        <p>
          - Twitter: <a href="https://twitter.com/okkhororg" target="_blank" rel="noopener noreferrer" title="Ver Twitter">@okkhororg</a> <br />
          {/* - Github: <a href="https://github.com/okkhororg" target="_blank" rel="noopener noreferrer" title="Ver Github">@okkhororg</a> <br /> */}
          {/* - Linkedin: <a href="https://www.linkedin.com/in/okkhororg/" target="_blank" rel="noopener noreferrer" title="Ver Linkedin">@okkhororg</a> */}
        </p>
      </S.PostFooterContent>
    </S.PostFooter>
  )
}

export default PostFooter
